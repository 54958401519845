import React, { useRef, useState, useEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';

import { AltBadge } from 'views/Main.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Draggable from 'react-draggable';
import Notes from 'views/Notes.js';
import PermissionsContainer from 'views/PermissionsContainer.js';
import ProgressBar from 'views/ProgressBar.js';
import ResizeObserver from 'react-resize-observer';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';

// export constants for components indexes and heights
export const CalloutIndex = 4950;
export const EndIndex = 4990;
export const FloatingLayerMenuIndex = 7500;
export const FloatingMenuIndex = 4550;
export const FrontIndex = EndIndex + 10;
export const ToolbarHeight = 20;

export const CollapseArrow = ({ className, collapsed, color, onClick, style }) => {

    const [_collapsed, _setCollapsed] = useState(collapsed);
    const [animations, setAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        transform: `rotate(${collapsed === true ? '0deg' : '180deg'})`
    }));

    const onCollapseClick = () => {
        let nextCollapse = !_collapsed;
        if(typeof(onClick) === 'function') {
            onClick(nextCollapse);
        }
        _setCollapsed(nextCollapse);
    }

    const getImage = () => {
        switch(color) {
            case Appearance.colors.primary():
            return `images/down-arrow-blue-small.png`;

            default:
            return 'images/down-arrow-grey-small.png';
        }
    }

    useEffect(() => {
        _setCollapsed(collapsed);
    }, [collapsed]);

    useEffect(() => {
        setAnimations({ transform: `rotate(${_collapsed === true ? '0deg' : '180deg'})` });
    }, [_collapsed]);

    return (
        <div syle={{
            height: 15,
            width: 15,
            ...style
        }}>
            <animated.img
            className={`text-button ${className || ''}`}
            onClick={onCollapseClick}
            src={getImage()}
            style={{
                height: 18,
                objectFit: 'contain',
                width: 18,
                ...style,
                ...animations
            }} />
        </div>
    )
}

export const getLayerSizingHeight = sizing => {
    if(Utils.isMobile() === true) {
        return '100%';
    }
    if(sizing === 'fullscreen') {
        return window.innerHeight - 75;
    }
    return window.innerHeight - 190 - (700 - getLayerSizingWidth(sizing));
}

export const getLayerSizingWidth = sizing => {
    switch(sizing) {
        case 'small':
        return 400;

        case 'medium':
        return 550;

        case 'extra_large':
        return window.innerWidth < 1000 ? window.innerWidth : 1000;

        case 'fullscreen':
        return window.innerWidth - 48;

        default:
        return 700;
    }
}

const Layer = ({ buttons, children, header, id, options = {}, style, title, utils }) => {

    const { extension, filter, loading, onClose, onCloseLayer, onHeightChange, onLayerIndexChange, onMount, onReposition, onSizingChange, position, removePadding, sizing, sticky, supportsFullscreen } = options;

    const buttonContainer = useRef(null);
    const headerRef = useRef(null);
    const ref = useRef(null);
    const scrollContainer = useRef(null);

    const [dragging, setDragging] = useState(false);
    const [extensionVisible, setExtensionVisible] = useState(false);
    const [height, setHeight] = useState(0);
    const [layerPosition, setLayerPosition] = useState(position);
    const [layerState, setLayerState] = useState('open');
    const [opacity, setOpacity] = useState(0);
    const [scale, setScale] = useState(0.75);
    const [size, setSize] = useState({
        height: window.innerHeight,
        type: sizing || 'large',
        width: window.innerWidth
    });
    const [top, setTop] = useState(window.innerHeight);
    const [zIndex, setZIndex] = useState(options.zIndex);
    
    const onAnimateComponents = action => {
        setOpacity(action === 'open' ? 1 : 0);
        setScale(action === 'open' ? 1 : 0.75);
        setTop(action === 'open' ? window.innerHeight : 0);
    }

    const onDealershipChange = () => {

        // all layers should be closed if the dealership changes at the root
        setLayerState('close');
    }

    const onDragEnded = (evt, node) => {

        // remove css class to disable text highlight for body
        document.body.classList.remove('disable-text-highlight');

        // determine if device is a mobile device
        if(Utils.isMobile() === true) {

            // default to page height incase mobile device does not return a y value
            let y = ref.current ? ref.current.getBoundingClientRect().y : window.innerHeight;
            setTop(y - 36);

            // update dragging flag and determine next steps
            // a close action is triggered if the drag distance is greater than 1/3 the screen height
            setTimeout(() => {
                setDragging(false);
                if(y > window.innerHeight / 3) {
                    setLayerState('close');
                } else {
                    setTop(0);
                }
            }, 50);
            return;
        }

        // set dragging flag and update layer position
        setDragging(false);
        setLayerPosition({
            x: node.x,
            y: node.y,
        });

        // notify subscribers of resposition if applicable
        if(typeof(onReposition) === 'function') {
            onReposition({
                id: id,
                position: {
                    x: node.x,
                    y: node.y
                }
            });
        }
    }

    const onDragMoved = (evt, node) => {

        // set dragging flag and update layer position
        setDragging(true);
        setLayerPosition({
            x: node.x,
            y: node.y
        });
    }

    const onDragStarted = () => {

        // add css class to disable text highlight for body, dragging the layer can sometime highlight text
        document.body.classList.add('disable-text-highlight');
        
        // notify subscribers that a layer interaction has taken place
        onLayerPress();
    }

    const onLayerPress = () => {

        // bring layer to the front of the visible stack
        setZIndex(FrontIndex);

        // notify subscribers of layer index change
        if(typeof(onLayerIndexChange) === 'function') {
            onLayerIndexChange(id);
        }
    }

    const onLayerStateChange = () => {

        // currently the only supported action is 'close'
        if(layerState === 'close') {

            // animate components out of view
            onAnimateComponents(layerState);

            // re-enable scroll functionality for document body
            document.body.style.overflowY = 'scroll';

            // notify root of layer close if applicable
            if(typeof(onClose) === 'function') {
                setTimeout(onClose.bind(this, id), 500);
            }

            // notify subscribers of layer close if applicable
            if(typeof(onCloseLayer) === 'function') {
                setTimeout(onCloseLayer.bind(this, id), 500);
            }
        }
    }

    const onLayerStateChangeRequest = ({ detail }) => {
        if(detail.id === id) {
            setLayerState(detail.value);
        }
    }

    const onMouseEnter = () => {
        document.body.style.overflowY = 'hidden';
    }

    const onMouseLeave = () => {
        document.body.style.overflowY = 'scroll';
    }

    const onPositionChange = position => {
        if(position) {
            setLayerPosition({
                y: position.y,
                x: Utils.isMobile() ? 12 : position.x
            });
        }
    }

    const onToggleExtension = () => {

        // prevent moving forward if feature is disabled for current user
        if(extension.permissions && extension.permissions.view) {
            let match = extension.permissions.view.find(key => utils.user.permissions.get(key) === false);
            if(match) {
                return utils.user.permissions.reject();
            }
        }

        // update flag for extension visibility
        setExtensionVisible(val => !val);
    }

    const onToggleFullscreen = () => {

        // reset layer position to center of screen if previous screen state was fullscreen
        if(size.type === 'fullscreen') {
            setLayerPosition({
                x: (size.width / 2) - (getLayerSizingWidth(sizing) / 2),
                y: height ? ((size.height / 2) - (height / 2)) : 50
            });
        }

        // update state for size type
        let next = size.type === 'fullscreen' ? (sizing || 'large') : 'fullscreen'
        setSize(props => ({
            ...props,
            type: next
        }));

        // notify subscribers of size change
        if(typeof(onSizingChange) === 'function') {
            onSizingChange(next);
        }
    }

    const onUpdateHeight = rect => {

        // update local state with new rect height
        setHeight(rect.height);

        // notify subscribers of height change if applicable
        if(typeof(onHeightChange) === 'function') {
            onHeightChange(rect.height)
        }
    }

    const onWindowSizeChange = () => {
        setSize(props => ({
            ...props,
            height: window.innerHeight,
            width: window.innerWidth
        }));
    }

    const getButtons = () => {

        let targets = buttons && buttons.filter(b => b.visible !== false) || [];
        return targets.length > 0 && (
            <div
            ref={buttonContainer}
            style={{
                borderTop: `1px solid ${Appearance.colors.divider()}`,
                padding: 12
            }}>
                <div style={{
                    margin: 'auto',
                    maxWidth: size.type === 'fullscreen' ? 400 : 700
                }}>
                    <div className={'row p-0 m-0'}>
                        {targets.map((button, index, buttons) => {

                            // determine padding based on the number of available buttons
                            let paddingX = 'px-0';
                            if(buttons.length === 2) {
                                paddingX = index === 0 ? 'pl-0 pr-1' : 'pl-1 pr-0';
                            } else if(buttons.length > 2) {
                                paddingX = index === 1 ? 'px-2' : 'px-0';
                            }

                            return (
                                <div
                                key={index}
                                className={`col-${parseInt(12 / buttons.length)} py-0 ${paddingX}`}>
                                    <Button
                                    {...button}
                                    label={button.text}
                                    loading={button.loading || loading === button.key}
                                    type={'large'}
                                    utils={utils}
                                    {...button}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    const getChildren = () => {

        // no additional logic is required if no children have been provided yet
        if(!children) {
            return null;
        }

        // if a single child is provided we assume the component is valid
        if(Array.isArray(children) == false) {
            return children;
        }

        // determine if at least one valid component was provided before returning children
        let component = children.find(child => child);
        if(component) {
            return children;
        }

        // return placeholder indicating that no content was provided
        return (
            <PermissionsContainer 
            permission={'__manual_overide'}
            utils={utils} 
            style={{
                height: '100%',
                minHeight: 250,
                width: '100%'
            }}/>
        );
    }

    const getExtensionContent = () => {
        
        // no additional logic is needed if an extension was not provided
        if(!extension || extensionVisible === false) {
            return null;
        }

        // determine which type of content to render
        let content = extension.content;
        switch(extension.type) {
            case 'notes':
            content = (
                <Notes
                abstract={extension.abstract}
                permissions={extension.permissions}
                utils={utils} />
            )
            break;
        }

        return (
            <div 
            className={'not-draggable'}
            style={{
                backgroundColor: Appearance.colors.layerBackground(),
                height: '100%',
                marginLeft: 8,
                maxHeight: size.height - 32,
                maxWidth: 450,
                overflowX: 'hidden',
                overflowY: 'scroll',
                width: window.innerWidth / 3,
                ...size.type !== 'fullscreen' && {
                    border: `3px solid ${window.theme === 'dark' ? 'rgba(25,25,25,1)' : 'white'}`,
                    borderRadius: 10,
                    boxShadow: '10px 0px 25px rgba(0, 0, 0, 0.1)'
                }
            }}>
                {content}
            </div>
        )
    }

    const getFilterOptions = () => {
        return filter && (
            <div
            className={'not-draggable'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 15,
            }}>
                <TextField
                icon={'search'}
                onChange={filter.onChange} 
                placeholder={filter.placeholder || 'Search for something...'}/>
            </div>
        )
    }

    const getHeaderContent = () => {

        // determine if a mobile header component is required
        if(Utils.isMobile() === true) {
            return (
                <div style={{
                    position: 'relative'
                }}>
                    <div
                    className={dragging ? 'cursor-grabbing':'hover-grab'}
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        width: '100%'
                    }}>
                        <div style={{
                            alignItems: 'center',
                            backgroundColor: window.theme === 'dark' ? 'rgba(25,25,25,1)' : 'white',
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                            boxShadow: '0px 5px 10px rgba(174,174,174,0.25)',
                            display: 'flex',
                            height: 30,
                            justifyContent: 'center',
                            paddingLeft: 15,
                            paddingRight: 15,
                            zIndex: 1
                        }}>
                            <div style={{
                                backgroundColor: Appearance.colors.grey(),
                                borderRadius: 2,
                                height: 4,
                                marginBottom: 2,
                                width: 45
                            }} />
                        </div>
                    </div>
                    {getLoadingComponent()}
                </div>
            )
        }

        // fallback to returning a desktop or tablet component
        return (
            <div
            className={dragging ? 'cursor-grabbing':'cursor-grab'}
            style={{
                position: 'relative'
            }}>
                <div style={{
                    ...Appearance.styles.header()
                }}>
                    {header}
                    <div
                    ref={headerRef}
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        height: ToolbarHeight,
                        minWidth: 0,
                        width: '100%'
                    }}>
                        <div
                        className={'not-draggable'}
                        style={{
                            width: 100
                        }}>
                            <AltBadge
                            onClick={setLayerState.bind(this, 'close')}
                            content={{
                                color: Appearance.colors.red,
                                text: 'Close'
                            }}
                            style={{
                                width: 60
                            }}/>
                        </div>

                        <span style={{
                            color: Appearance.colors.text(),
                            flexGrow: 1,
                            fontSize: 14,
                            fontWeight: '700',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textAlign: 'center',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{title}</span>

                        <div
                        className={'not-draggable'}
                        style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: 100
                        }}>
                            {getRightAccessoryButton()}
                        </div>
                    </div>
                </div>
                {getLoadingComponent()}
            </div>
        )
    }

    const getLayerAxis = () => {
        return Utils.isMobile() === true ? 'y' : null;
    }

    const getLayerBounds = () => {
        return Utils.isMobile() === true && { top: 12 };
    }

    const getLayerContentHeight = () => {

        // layer content should cover as much of the screen as possible for mobile devices
        if(Utils.isMobile() === true) {
            return '100%';
        }

        // calculate a height for the inner content minus the accessory views
        let height = size.height - (headerRef.current ? headerRef.current.clientHeight : 0) - (buttonContainer.current ? buttonContainer.current.clientHeight : 0);
        return size.type === 'fullscreen' ? (height - 20) : (height - 60);
    }

    const getLayerHeight = () => {

        // mobile devices use the full height of the device minus 12px padding
        if(Utils.isMobile() === true) {
            return window.innerHeight - 24;
        }

        // tablet and desktop devices will auto calculate the required height
        return 'auto';
    }

    const getLayerPosition = () => {

        // return a fixed position with padding offset for mobile devices
        if(Utils.isMobile() === true) {
            return {
                x: 12,
                y: 12
            }
        }

        // return a fixed position with no offset for fullscreen layers
        if(size.type === 'fullscreen') {
            return {
                x: 0,
                y: 0
            }
        }

        // return an explicit layer position if available
        if(layerPosition) {
            return layerPosition;
        }

        // return a freshly calculated position using the screen size and requested layer sizing
        return {
            x: (size.width / 2) - (getLayerWidth() / 2),
            y: height ? (size.height / 2) - (height / 2) : 50
        }
    }

    const getLayerWidth = () => {

        // mobile devices use the full width of the device minus 12px padding
        if(Utils.isMobile() === true) {
            return window.innerWidth - 24;
        }

        // fullscreen layers use the full width of the device
        if(size.type === 'fullscreen') {
            return window.innerWidth;
        }

        // calculate a layer width using the requested sizing
        let width = getLayerSizingWidth(size.type);
        return size.width > width ? width : size.width - 60;
    }

    const getLoadingComponent = () => {
        return loading === true && (
            <div style={{
                borderRadius: 2,
                bottom: 0,
                left: 0,
                height: 2,
                overflow: 'hidden',
                position: 'absolute',
                right: 0
            }}>
                <ProgressBar/>
            </div>
        )
    }

    const getPrimaryContent = () => {
        return (
            <div style={{
                backgroundColor: Appearance.colors.layerBackground(),
                width: getLayerWidth(),
                ...size.type !== 'fullscreen' && {
                    border: `3px solid ${window.theme === 'dark' ? 'rgba(25,25,25,1)' : 'white'}`,
                    borderRadius: 10,
                    boxShadow: '0px 0px 50px rgba(0,0,0,0.2)'
                }
            }}>
                <ResizeObserver onResize={onUpdateHeight}/>
                <div
                ref={ref}
                style={{
                    flexGrow: 1,
                    margin: 0,
                    padding: 0
                }}>
                    {getHeaderContent()}
                    {getFilterOptions()}
                    <div
                    className={'not-draggable'}
                    style={{
                        position: 'relative'
                    }}>
                        {sticky && sticky.top}
                        <div 
                        ref={scrollContainer}
                        className={shouldShowScrollbars() ? 'custom-scrollbars' : ''}
                        style={{
                            height: size.type === 'fullscreen' ? getLayerContentHeight() : '100%',
                            maxHeight: getLayerContentHeight(),
                            overflowX: 'hidden',
                            overflowY: 'scroll',
                            padding: removePadding ? 0 : 12,
                            ...style,
                        }}>
                            {getChildren()}
                        </div>
                        {sticky && sticky.bottom}
                        {getButtons()}
                    </div>
                </div>
            </div>
        )
    }

    const getRightAccessoryButton = () => {
        if(supportsFullscreen) {
            return (
                <AltBadge
                onClick={onToggleFullscreen}
                content={{
                    color: size.type === 'fullscreen' ? Appearance.colors.grey() : Appearance.colors.green,
                    text: size.type === 'fullscreen' ? 'Standard' : 'Fullscreen'
                }}
                style={{
                    width: 95
                }}/>
            )
        }
        if(extension) {
            switch(extension.type) {
                case 'notes':
                return (
                    <AltBadge
                    onClick={onToggleExtension}
                    content={{
                        color: Appearance.colors.secondary(),
                        text: 'Notes'
                    }} />
                )

                default:
                return (
                    <AltBadge
                    onClick={onToggleExtension}
                    content={extension.button} />
                )
            }
        }
        return null;
    }

    const getVelocityAnimationValues = () => {

        // return top animation value if device is mobile
        if(Utils.isMobile() === true) {
            return { top }
        }
        
        // return scale and opacity animation values for tablet and desktop devices
        return { scale, opacity }
    }

    const shouldShowScrollbars = () => {
        return scrollContainer.current ? scrollContainer.current.scrollHeight > getLayerContentHeight() : false;
    }

    useEffect(() => {
        if(options.layerState) {
            setLayerState(options.layerState);
        }
    }, [options.layerState]);

    useEffect(() => {
        onLayerStateChange();
    }, [layerState]);

    useEffect(() => {
        setZIndex(options.zIndex);
    }, [options.zIndex]);

    useEffect(() => {
        onPositionChange(position);
    }, [position]);

    useEffect(() => {
        setSize(props => ({
            ...props,
            type: sizing || 'large'
        }));
    }, [sizing]);

    useEffect(() => {
        setHeight(ref.current && ref.current.clientHeight || 0);
    }, [ref.current]);

    useEffect(() => {

        // animate components into view
        onAnimateComponents('open');

        // register window size change listener
        window.addEventListener('resize', onWindowSizeChange);

        // register event listeners  
        utils.events.on(id, 'dealership_change', onDealershipChange);
        utils.events.on(id, 'layer_state_change_request', onLayerStateChangeRequest);

        // determine if an mount callback was provided
        if(typeof(onMount) === 'function') {
            let { position } = onMount({ getLayerSizingWidth, options });
            if(position) {
                setLayerPosition(position);
            }
        }

        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
            utils.events.off(id, 'dealership_change', onDealershipChange);
            utils.events.off(id, 'layer_state_change_request', onLayerStateChangeRequest);
        }
    }, []);

    return (
        <Draggable
        axis={getLayerAxis()}
        bounds={getLayerBounds()}
        cancel={'.not-draggable'}
        defaultPosition={getLayerPosition()}
        key={id}
        onDrag={onDragMoved}
        onStart={onDragStarted}
        onStop={onDragEnded}
        onTouchEnd={onDragEnded}
        position={getLayerPosition()}>
            <div
            id={id}
            style={{
                position: 'fixed',
                zIndex: zIndex
            }}>
                <VelocityComponent
                animation={getVelocityAnimationValues()}
                duration={dragging ? 0 : 500}
                easing={[250, 20]}>
                    <div 
                    onClick={onLayerPress}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: getLayerHeight(),
                        position: 'fixed',
                        width: 'auto',
                        zIndex: zIndex
                    }}>
                        {getPrimaryContent()}
                        {getExtensionContent()}
                    </div>
                </VelocityComponent>
            </div>
        </Draggable>
    )
}

export const LayerItem = ({ badge, children, childrenStyle, collapsed, headerStyle, onVisibilityChange, title, required, rightContent, style }) => {

    const [_collapsed, _setCollapsed] = useState(collapsed);
    const [animations, setAnimations] = useSpring(() => ({
        opacity: collapsed ? 0 : 1,
        maxHeight: collapsed ? 0 : 1000,
        config: { mass: 1, tension: 180, friction: 30 }
    }));

    const onCollapseClick = () => {
        let next = !_collapsed;
        _setCollapsed(next);
        if(typeof(onVisibilityChange) === 'function') {
            onVisibilityChange(next);
        }
    }

    const isCollapseEnabled = () => {
        return typeof(_collapsed) === 'boolean' ? true : false;
    }

    const getAnimationsStyles = () => {
        if(isCollapseEnabled()) {
            return {
                overflowX: 'hidden',
                overflowY: 'scroll',
                ...animations
            };
        }
        return null;
    }

    const getCollapseStyles = () => {
        if(!isCollapseEnabled() || _collapsed === false) {
            return {
                marginBottom: 20
            };
        }
        return {
            paddingBottom: 2,
            marginBottom: _collapsed ? 10 : 20,
            borderBottom: `1px solid ${Appearance.colors.divider()}`
        }
    }

    useEffect(() => {
        setAnimations({
            opacity: _collapsed ? 0 : 1,
            maxHeight: _collapsed ? 0 : 1000
        });
    }, [_collapsed]);

    useEffect(() => {
        _setCollapsed(collapsed);
    }, [collapsed]);

    return (
        <div style={{
            width: '100%',
            ...getCollapseStyles(),
            ...style
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                textAlign: 'left',
                width: '100%'
            }}>
                {required && (
                    <div style={{
                        width: 8,
                        height: 8,
                        minWidth: 8,
                        minHeight: 8,
                        borderRadius: 4,
                        overflow: 'hidden',
                        backgroundColor: Appearance.colors.red,
                        marginRight: 8
                    }} />
                )}
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    minWidth: 0,
                    ...headerStyle
                }}>
                    <span style={{
                        ...Appearance.textStyles.subHeader(),
                        display: 'block',
                        paddingRight: 12
                    }}>{title}</span>
                    {badge && (
                        <AltBadge
                        content={badge}
                        style={{
                            top: 0,
                            marginLeft: 8,
                            marginRight: 0
                        }} />
                    )}
                </div>
                {rightContent}
                {isCollapseEnabled() && (
                    <CollapseArrow
                    collapsed={_collapsed}
                    onClick={onCollapseClick} />
                )}
            </div>
            <animated.div style={{
                width: '100%',
                paddingTop: 8,
                ...childrenStyle,
                ...getAnimationsStyles()
            }}>
                {children}
            </animated.div>
        </div>
    )
}

export default Layer;
