import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';

import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper';
import Appearance from 'styles/Appearance.js';
import Layer from 'structure/Layer.js';
import Views from 'views/Main.js';

const ConfigureFileProperties = ({ file, onChange }, { index, options, utils }) => {

    const layerID = `configure_field_properties_${file.file_name}`;
    const [edits, setEdits] = useState(file);
    const [layerState, setLayerState] = useState(null);

    const onUpdateTarget = props => {
        setEdits(prev => ({
            ...prev, 
            ...props
        }));
    }

    const onSubmitChanges = async () => {
        try {

            // validate fields before moving on
            await validateRequiredFields(getFields);

            // close layer and notify subscribers of data change
            setLayerState('close');
            if(typeof(onChange) === 'function') {
                onChange(edits);
            }
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your changes. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'confirm',
            onClick: onSubmitChanges,
            text: 'Done'
        }]
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'About this File',
            items: [{
                component: 'textfield',
                key: 'name',
                onChange: text => onUpdateTarget({ name: text }),
                title: 'Name',
                value: edits.name
            },{
                component: 'textview',
                key: 'description',
                onChange: text => onUpdateTarget({ description: text }),
                title: 'Description',
                value: edits.description
            }]
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Configure File "${file.file_name}"`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const FileHandler = {
    configureFile: (utils, file, callback) => {
        utils.layer.open({
            id: `configure_field_properties_${file.file_name}`,
            Component: ConfigureFileProperties.bind(this, {
                file: file,
                onChange: callback
            })
        });
    },
    getMimeTypes: type => {
        switch(type) {
            case 'txt':
            return ['text/plain', 'public.plain-text'];

            case 'doc':
            case 'docx':
            return ['application/msword', 'com.microsoft.word.doc'];

            case 'jpg':
            return ['image/jpeg'];

            case 'mov':
            return ['video/mov'];

            case 'mp4':
            return ['video/mp4'];

            case 'm4v':
            return ['video/m4v'];

            case 'pdf':
            return ['application/pdf', 'com.adobe.pdf'];

            case 'png':
            return ['image/png'];

            case 'xls':
            case 'xlsx':
            return [
                'application/vnd.ms-excel',
                'com.microsoft.excel.xls',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ];

            default:
            return [];
        }
    },
    parse: files => {
        return new Promise(async resolve => {

            // prepare file reader
            let reader = new FileReader();

            // loop through files and fetch contents
            let results = [];
            for(const file of files) {
                let result = await FileHandler.readFile(reader, file);
                results.push(result);
            }
            resolve(results);
        });
    },
    readFile: async (reader, file) => {
        return new Promise(resolve => {
            reader.onload = data => {
                resolve({
                    data: data.target.result,
                    file_name: file.name,
                    file_type: file.type,
                    mime: file.type
                });
            };
            reader.readAsDataURL(file);
        });
    }
}

const FilePickerField = ({ fileTypes, metadata = false, multiple = false, onChange, style, utils, value,  }) => {

    const [files, setFiles] = useState([]);
    const [label, setLabel] = useState('No file selected...');

    const getFileTypes = () => {

        // allow all file types if no explicit file types were requested
        if(!fileTypes || !Array.isArray(fileTypes)) {
            return '*';
        }

        // loop through file types and prepare list of accepted file types
        return fileTypes.map(type => FileHandler.getMimeTypes(type)).join(', ');
    }

    const onConfigureFile = index => {
        FileHandler.configureFile(utils, files[index], result => {

            // update files state with new metadata
            let next = update(files, {
                [index]: {
                    $set: result
                }
            });
            setFiles(next);

            // notify subscribers of files change
            if(typeof(onChange) === 'function') {
                onChange(multiple ? next : next[0]);
            }
        });
    }

    const onFileChange = async ({ target }) => {

        // prevent moving forward if no files were selected
        if(target.files.length === 0) {
            return;
        }

        // parse selected files
        let results = await FileHandler.parse(target.files);

        // update state with selected files
        setFiles(prev => {
            if(multiple === false) {
                return results;
            }
            return prev ? prev.concat(results) : results;
        });

        // notify subscribers of files change
        if(typeof(onChange) === 'function') {
            onChange(multiple ? results : results[0]);
        }
    }

    useEffect(() => {
        setLabel(files.length > 0 ? (multiple ? `${files.length} selected` : files[0].file_name) : 'No files selected...');
    }, [files]);

    useEffect(() => {
        if(!value) {
            setFiles([]);
            return;
        }
        setFiles(multiple ? value : [value]);
    }, [value]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            width: '100%',
            ...style
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                <div
                className={`custom-file ${window.theme}`}
                style={{
                    flexGrow: 1,
                    overflow: 'hidden'
                }}>
                    <input
                    accept={getFileTypes()}
                    className={`custom-file-input ${window.theme}`}
                    multiple={multiple}
                    onChange={onFileChange}
                    type={'file'}/>
                    <label
                    className={`custom-file-label ${window.theme}`}
                    style={{
                        color: files.length > 0 ? Appearance.colors.text() : Appearance.colors.subText(),
                        fontSize: 12,
                        fontWeight: 500
                    }}>{label}</label>
                </div>
            </div>
            {metadata && files.length > 0 && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {files.map((file, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== files.length - 1,
                                icon: { 
                                    path: getFileIconPath(file.file_type),
                                    imageStyle: {
                                        backgroundColor: Appearance.colors.transparent,
                                        borderRadius: 0,
                                        boxShadow: 'none',
                                        objectFit: 'contain'
                                    } 
                                },
                                key: index,
                                onClick: onConfigureFile.bind(this, index),
                                subTitle: file.description || 'Click to configure file name and description',
                                title: file.name || file.file_name,
                                rightContent: (!file.name || !file.description) && (
                                    <div style={{
                                        backgroundColor: Appearance.colors.red,
                                        borderRadius: 4,
                                        height: 5,
                                        marginLeft: 8,
                                        minWidth: 5,
                                        width: 5
                                    }} />
                                )
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export const getFileIconPath = type => {
    switch(type) {

        case 'application/msword':
        case 'com.microsoft.word.doc':
        return window.theme === 'dark' ? 'images/doc-icon-white.png' : 'images/doc-icon-grey.png';

        case 'application/pdf':
        case 'com.adobe.pdf':
        return window.theme === 'dark' ? 'images/pdf-icon-white.png' : 'images/pdf-icon-grey.png';

        case 'video/mp4':
        case 'video/m4v':
        case 'video/mov':
        return window.theme === 'dark' ? 'images/general-icon-white.png' : 'images/general-icon-grey.png';

        case 'application/vnd.ms-excel':
        case 'com.microsoft.excel.xls':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return window.theme === 'dark' ? 'images/xls-icon-white.png' : 'images/xls-icon-grey.png';

        default:
        return window.theme === 'dark' ? 'images/general-icon-white.png' : 'images/general-icon-grey.png';
    }
}

export default FilePickerField;

