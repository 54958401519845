import React, { useEffect, useRef, useState } from 'react';
import 'styles/main.css';

import { animated, useSpring } from '@react-spring/web';
import { getContent } from 'files/Content.js';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment-timezone';
import smoothscroll from 'smoothscroll-polyfill';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import { AddEditLead, Dialer, ImportLeads, LeadDetails, UnassignLeads } from 'managers/Leads.js';
import Alert from 'views/Alert.js';
import AlertStack from 'views/AlertStack.js';
import Appearance from 'styles/Appearance.js';
import Content from 'managers/Content.js';
import Cookies from 'js-cookie';
import DatePicker, { DualDatePicker } from 'views/DatePicker.js';
import { DealershipOverview, DealershipPreferences, SurveyMonkeyPreferences } from 'managers/Dealerships.js';
import DesktopNotification from 'views/DesktopNotification.js';
import Demo from 'classes/Demo.js';
import DemoRequest from 'classes/DemoRequest.js';
import { DndProvider } from 'react-dnd';
import { EndIndex } from 'structure/Layer.js';
import Event from 'classes/Event.js';
import Events from 'managers/Events.js';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ImportDemos } from 'managers/Demos.js';
import Lead from 'classes/Lead.js';
import Loader from 'views/Loader.js';
import Login, { runLogin } from 'views/Login.js';
import Notification from 'classes/Notification.js';
import NotificationCenter from 'files/NotificationCenter.js';
import OverviewItem from 'views/OverviewItem.js';
import QueryString from 'query-string';
import Request from 'files/Request.js';
import RequestAgreement from 'views/RequestAgreement.js';
import Sheet from 'views/Sheet.js';
import Sidebar from 'structure/Sidebar.js';
import Sockets from 'managers/Sockets.js';
import Status from 'classes/Status.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';
import WebView from 'views/WebView.js';

const ContentManager = Content.new();
const EventsManager = Events.new();
const SocketManager = Sockets.new();
const Stripe = loadStripe(API.stripe);

window.theme = 'light';
window.__forceSmoothScrollPolyfill__ = true;

const App = () => {

    const dealershipRef = useRef(null);
    const fetching = useRef({});
    const groupsRef = useRef(null);
    const layersRef = useRef(null);
    const overviewProps = useRef({ 
        end_date: moment().endOf('day'), 
        start_date: moment().startOf('day') 
    });
    const selectedSlot = useRef(null);
    const statusCodesRef = useRef(null);
    const userRef = useRef(null);

    const [active, setActive] = useState({ view: null });
    const [activeDealership, setActiveDealership] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [container, setContainer] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        opacity: 1,
        top: 0
    }));
    const [content, setContent] = useState([]);
    const [datePicker, setDatePicker] = useState(null);
    const [datePickerOverride, setDatePickerOverride] = useState(null);
    const [groups, setGroups] = useState(null);
    const [layers, setLayers] = useState([]);
    const [layerIndex, setLayerIndex] = useState([]);
    const [loader, setLoader] = useState(null);
    const [loading, setLoading] = useState(false);
    const [nonce, setNonce] = useState(moment().unix());
    const [notification, setNotification] = useState(null);
    const [overview, setOverview] = useState(null);
    const [permissions, setPermissions] = useState({});
    const [preflight, setPreflight] = useState(false);
    const [requestAgreement, setRequestAgreement] = useState(null);
    const [sheet, setSheet] = useState(null);
    const [sidebar, setSidebar] = useState(-500);
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [socketsConnected, setSocketsConnected] = useState(false);
    const [statusCodes, setStatusCodes] = useState([]);
    const [theme, setTheme] = useState('light');
    const [user, setUser] = useState(null);

    const onActiveDealershipChange = async () => {
        try {

            // prevent moving forward if no dealership was provided or if websockets have not yet connected
            if(!activeDealership || socketsConnected === false) {
                throw new Error(socketsConnected ? 'no dealership provided' : 'websockets not connected yet');
            }

            // update active dealership ref
            dealershipRef.current = activeDealership;

            // notify subscribers of dealership change
            utils.events.emit('dealership_change', activeDealership);

            // fetch custom status codes and stats overview
            fetchDealershipStatusCodes();
            fetchOverview();

            // update default dealership for future logins
            Cookies.set('default_dealership_id', activeDealership.id);

            // loop through namespaces and join dealership room
            utils.sockets.namespaces.forEach(namespace => {
                utils.sockets.persistEmit(namespace, 'join', { dealership_id : activeDealership.id });
            });

            // set subscriptions for dealership preference changes and account based notifications
            console.log(activeDealership.id, `on_notification_${userRef.current.user_id}`)
            await utils.sockets.persistOn('dealerships', 'on_update_preferences', onUpdateDealershipPreferences);
            await utils.sockets.persistOn('dealerships', 'on_overview_change', onDealershipOverviewChange);
            await utils.sockets.persistOn('notifications', `on_notification_${userRef.current.user_id}`, onNotification);
            await utils.sockets.persistOn('notifications', `on_notification_level_${userRef.current.level}`, onNotification);

            // print to console the socket join for the active dealership 
            console.log(`[active_dealership_change]: joined dealership ${activeDealership.id}`);

        } catch(e) {
            console.error(`[active_dealership_change]: ${e.message}`);
        }
    }

    const onAFTActiveRequest = async () => {
        try {
            console.log('active request receieved');
            await utils.sockets.emit('aft', 'active');
        } catch(e) {
            console.error(e.message);
        }
    }

    const onCloseLayer = layerID => {

        setLayerIndex(layerIndex => update(layerIndex, {
            $apply: ids => ids.filter(id => id !== layerID)
        }))
        setLayers(layers => {
            let updatedLayers = update(layers, {
                $apply: layers => layers.map(layer => {
                    if(layer.id === layerID) {
                        layer.visible = false;
                    }
                    return layer;
                })
            });
            let remainingLayers = updatedLayers.filter(layer => {
                return layer.visible !== false
            });
            if(remainingLayers.length === 0) {
                console.log('layers reset');
                document.body.style.overflowY = 'scroll';
                return [];
            }
            return updatedLayers;
        });
    }

    const onDealershipOverviewChange = evt => {
        try {
            let { data, end_date, start_date } = evt;
            if(overviewProps.current.end_date.format('YYYY-MM-DD') !== end_date || overviewProps.current.start_date.format('YYYY-MM-DD') !== start_date) {
                console.warn('[websockets]: on_overview_change ignored, date mismatch');
                return;
            }
            
            console.log('[websockets]: on_overview_change');
            setOverview(data);

        } catch(e) {
            console.error(`[websockets]: on_overview_change error, ${e.message}`);
        }
    }

    const onDealershipSettingsClick = () => {
        utils.layer.open({
            id: `dealership_preferences_${activeDealership.id}`,
            abstract: Abstract.create({
                type: 'dealership',
                object: activeDealership
            }),
            Component: DealershipPreferences
        });
    }

    const onDemoStatusChange = props => {
        try {

            // prevent moving forward if event data was not provided
            if(!props.id || !props.status) {
                throw new Error('[on_demo_status_change]: event data not found');
            }

            // update listeners with new target object if applicable
            utils.content.update({
                object: props,
                type: 'demo_status'
            });

        } catch(e) {
            console.error(`[on_demo_status_change]: ${e.message}`);
        }
    }

    const onDemoRequestStatusChange = props => {
        try {

            // prevent moving forward if event data was not provided
            if(!props.id || !props.status) {
                throw new Error('[on_demo_request_status_change]: event data not found');
            }

            // update listeners with new target object if applicable
            utils.content.update({
                object: props,
                type: 'demo_request_status'
            });

        } catch(e) {
            console.error(`[on_demo_request_status_change]: ${e.message}`);
        }
    }

    const onLayerReposition = ({ id, position }) => {

        let index = layers.findIndex(layer => id === layer.id);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers => update(layers, {
            [index]: {
                position: {
                    $set: position
                }
            }
        }))
    }


    const onOverviewUpdate = evt => {
        console.log(evt);
    }
    
    const onLeadStatusChange = props => {
        try {
            // prevent moving forward if event data was not provided
            if(!props.id || !props.status) {
                throw new Error('[on_lead_status_change]: event data not found');
            }

            // update listeners with new target object if applicable
            utils.content.update({
                object: props,
                type: 'lead_status'
            });

        } catch(e) {
            console.error(`[on_lead_status_change]: ${e.message}`);
        }
    }
    
    const onLogin = async ({ default_dealership, groups, user }) => {
        try {

            // show full screen loader
            await utils.loader.show();

            // set active user
            userRef.current = user;
            setPermissions(user.permissions);
            setUser(user);

            // set user groups and active dealership
            dealershipRef.current = default_dealership || user.dealership;
            setActiveDealership(dealershipRef.current);
            setGroups(groups);

            // connect to websockets and setup subscriptions
            connectToSockets(user);
            await utils.loader.hide();

            // animate components into view and set default view
            setActive({ view: 'reports', subView: 'programs' });
            setContainer({ opacity: 1, top: 0 });
            setSidebar(0);

            // process optional post-login query parameters
            onProcessPostLoginQueryParameters();

            // set dealership level listeners for content changes
            utils.content.subscribe('root', ['status', 'user'], {
                onFetch: type => {
                    if(type === 'status') {
                        fetchDealershipStatusCodes();
                        return;
                    }
                },
                onUpdate: abstract => {
                    switch(abstract.type) {
                        case 'status':
                        setStatusCodes(codes => {
                            return codes.map(prev => {
                                return prev.id === abstract.getID() ? abstract.object : prev;
                            });
                        });
                        return;

                        case 'user':
                        setUser(() => {
                            if(userRef.current.user_id === abstract.getID()) {
                                abstract.object.token = userRef.current.token;
                                abstract.object.dealership = userRef.current.dealership;
                            }
                            return user;
                        });
                        return;
                    }
                }
            });

            // notify user of change notes if applicable
            let notified = Cookies.get(API.app_version) || false;
            if(API.change_notes && notified === false) {
                utils.alert.show({
                    title: `Version ${API.app_version}`,
                    message: API.change_notes,
                    onClick: () => {
                        Cookies.set(API.app_version, moment().unix());
                    }
                });
            }

        } catch(e) {
            utils.loader.hide();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your content. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onLoginAsUser = async token => {
        try {

            // set loading flag and run login with single-use token
            await utils.loader.show();
            let result = await Request.post(utils, '/users/', {
                token: decodeURIComponent(token),
                type: 'validate_single_use_login_token'
            });
            console.log(result);

            // set user manually so login ui doesn't render after preflight flag is updated
            let user = User.create(result.user);
            setUser(user);
            
            // run post-login logic
            onLogin(result);

        } catch(e) {
            utils.loader.hide();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing the login process. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onNavigationChange = ({ level, subView, view }) => {

        // prevent moving forward if the requested destination has already been set
        if((view === active.view && !subView) && subView === active.subView) {
            return;
        }

        // determine if view is meant for a different account type
        if(utils.user.get().level > level) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Your account is not able to view this information. Please speak with your dealer if you have any questions'
            });
            return;
        }

        // loop through view types and determine if a custom action is requried
        switch(view) {
            case 'demos':
            switch(subView) {
                case 'import':
                utils.layer.open({
                    id: 'import_demos',
                    title: 'Import Demos',
                    Component: ImportDemos
                });
                return;
            }
            break;

            case 'dialer':
            utils.layer.open({
                id: 'dialer',
                title: 'Dialer',
                Component: Dialer
            });
            return;

            case 'leads':
            switch(subView) {
                case 'new':
                onNewLeadClick();
                return;

                case 'import':
                utils.layer.open({
                    id: 'import_leads',
                    title: 'Import Leads',
                    Component: ImportLeads
                });
                return;

                case 'unassign_leads':
                utils.layer.open({
                    id: 'unassign_leads',
                    title: 'Unassign Leads',
                    Component: UnassignLeads
                });
                return;
            }
            break;

            case 'programs_and_surveys':
            switch(subView) {
                case 'survey_monkey':
                utils.layer.open({
                    id: 'survey_monkey_preferences',
                    title: 'Survey Monkey Preferences',
                    Component: SurveyMonkeyPreferences
                });
                return;
            }
            break;

            case 'logout':
            onRequestLogout();
            return;

            case 'settings':
            onDealershipSettingsClick();
            return;
        }

        // scroll current page to the top and update active route
        window.scrollTo(0, 0);
        setActive({ subView, view });
    }

    const onNewLeadClick = () => {

        // create new lead and set primary user
        let lead = Lead.new();
        lead.user = utils.user.get();

        // open lead details layer
        utils.layer.open({
            id: 'new_lead',
            abstract: Abstract.create({
                type: 'lead',
                object: lead
            }),
            Component: AddEditLead.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onShowAFTContent = async data => {
        try {

            // verify that content came from the same dealership
            if(data.dealership_id !== utils.dealership.get().id) {
                throw new Error('It looks like this information is meant for another dealership. Please check that you have the same dealership selected in AFT and Global Data')
            }

            // set new page title
            document.title = 'Global Data: AFT Content Available';

            // loop through content types
            let { id, type } = data.props || {};
            switch(type) {
                case 'lead':
                let lead = await Lead.get(utils, id);
                utils.layer.open({
                    abstract: Abstract.create({
                        object: lead,
                        type: type
                    }),
                    Component: LeadDetails,
                    id: `lead_details_${id}`,
                    permissions: ['leads.details']
                })
                break;

                default:
                throw new Error('Unsupported content found in the request');
            }
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the content that you sent from AFT. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewDemo = () => {
        utils.content.fetch('demo');
    }

    const onNewDemoRequest = () => {
        utils.content.fetch('demo_request');
    }

    const onNewEvent = () => {
        utils.content.fetch('event');
    }

    const onNewGroup = ({ group }) => {
        try {
            console.log(`added group ${group.id}`);
            if(!groupsRef.current) {
                return;
            }
            setGroups(update(groupsRef.current, {
                $push: [ group ]
            }));
        } catch(e) {
            console.error(e.message);
        }
    }

    const onNewLead = () => {
        utils.content.fetch('lead');
    }

    const onNotification = data => {
        try {

            // create notification and update notification state
            let notification = Notification.create(data);
            console.log(data);

            // notify subscribers that notifications need to be fetched
            utils.content.fetch('notification');

            // determine if any other subscribers need to be notified
            switch(notification.category) {
                case 'BACKGROUND_LEAD_ASSIGNMENTS_COMPLETED':
                case 'BACKGROUND_LEAD_UNASSIGNMENTS_COMPLETED':
                utils.content.fetch('lead');
                break;

                case 'NEW_SMS_MESSAGE':
                let lead = layersRef.current.find(layer => layer.abstract && layer.abstract.getTag() === `lead-${data.payload.lead_id}`);
                if(lead) {
                    console.warn(`[on_notification]: new message notification silenced, lead layer already open for ${data.payload.lead_id}`);
                    return;
                }
                break;
            }

            // update local state with new notification for banner display
            setNotification(notification);
            
        } catch(e) {
            console.error(e.message);
        }
    }

    const onOpenLayer = async target => {

        // verify that user has permission to open layer if permissions were provided
        if(target.permissions) {
            let match = target.permissions.find(key => utils.user.permissions.get(key) === false);
            if(match) {
                utils.user.permissions.reject();
                return;
            }
        }

        // move layer to front of stack if layer is already open
        let index = layerIndex.findIndex(id => id === target.id);
        if(index >= 0) {
            setTimeout(() => {
                setLayerIndex(indexes => {
                    let results = update(indexes, {
                        $splice: [[index, 1]],
                        $unshift: [target.id]
                    });
                    return results;
                });
            }, 0);
            return;
        }

        // update layers and indexes after a slight timeout
        setTimeout(() => {

            // update layers list
            setLayers(layers => {
                return update(layers, {
                    $push: [target]
                });
            });

            // update layer indexes list
            setLayerIndex(indexes => {
                return update(indexes, {
                    $unshift: [target.id]
                });
            });
        }, 0);
    }

    const onOpenLayerMultiple = targets => {

        // add layers to stack and setup onMount callback
        // sizes are calculated inside of the layer and returned through the onMount callback
        setTimeout(() => {
            setLayers(layers => {
                return update(layers, {
                    $push: targets.map((layer, index) => {
                        layer.onMount = ({ getLayerSizingWidth }) => {
                            let width = getLayerSizingWidth();
                            let center = window.innerWidth / 2;
                            return {
                                position: {
                                    y: 12,
                                    x: index === 0 ? ((center - width) - 6) : (center + 6)
                                }
                            }
                        }
                        return layer;
                    })
                });
            });
            setLayerIndex(layerIndex => {
                return update(layerIndex, {
                    $unshift: targets.map(layer => layer.id)
                });
            });
        }, 0)
    }

    const onPermissionsChange = data => {

        // update local state with new permissions data
        setPermissions({ ...data });
        setUser(user => {
            user.permissions = data;
            return user;
        });

        // notify user that permissions have changed if development environment is not enabled
        if(API.dev_env === false) {
            utils.alert.show({
                title: 'Permissions Updated',
                message: `The permissions for your account have been updated. ${userRef.current.level > User.levels.get().dealer ? 'Please speak with your dealer if you have any questions.' : 'Please contact Home Office if you have any questions.'}`
            });
        }
    }

    const onProcessPostLoginQueryParameters = () => {

        // determine next steps for query route parameter
        let parameters = QueryString.parse(window.location.search);
        switch(parameters.route) {

            case 'lead_details':
            onShowLeadDetails(parameters.lead_id);
            break;

            case 'single_sign_on':
            onRequestAutoLoginPreference(userRef.current.refresh_token);
            break;

            // determine if a lead id was provided in the query without a route, this is depreciated
            default:
            if(parameters.lead_id) {
                onShowLeadDetails(parameters.lead_id);
            }
        }

        // remove query parameters from the query string
        window.history.pushState(null, 'Applied Fire Technologies', '/');
    }

    const onRemoveGroup = ({ group }) => {
        try {
            console.log(`removed group ${group.id}`);
            if(!groupsRef.current) {
                return;
            }
            setGroups(groupsRef.current.filter(prevGroup => {
                return prevGroup.id !== group.id;
            }));
        } catch(e) {
            console.error(e.message);
        }
    }

    const onRequestAutoLoginPreference = token => {
        utils.alert.show({
            title: 'Remember My Login',
            message: 'Would you like us to automatically log you into this account next time you visit the website?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {

                    // update cookies with access token for cross-platform logins
                    let preferences = utils.graci.preferences.get();
                    preferences.auto_login = true;
                    preferences.last_login = moment().unix();
                    preferences.refresh_token = token;
                    utils.graci.preferences.set(preferences);
                    return;
                }
            }
        });
    }

    const onRequestLogout = () => {
        utils.alert.show({
            title: 'Logout',
            message: 'Are you sure that you want to logout of your account?',
            buttons: [{
                key: 'logout',
                title: 'Logout',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Logout',
                style: 'default'
            }],
            onClick: async key => {
                try {

                    if(key !== 'logout') {
                        return;
                    }

                    // reset ui back to pre-login state
                    setSidebar(-500);
                    setLayers([]);
                    setLayerIndex([]);
                    setContainer({
                        top: -200,
                        opacity: 0
                    });

                    // reset navigation back to pre-login state
                    await Utils.sleep(0.25);
                    setActive({ view: null });

                    // remove user, groups, and dealership details
                    setActiveDealership(null);
                    setGroups(null);
                    setUser(null);

                    // update autologin flag for graci preferences
                    let result = utils.graci.preferences.get();
                    result.auto_login = false;
                    utils.graci.preferences.set(result);

                } catch(e){
                    console.error(e.message);
                }
            }
        })
    }

    const onRestrictedTextClick = evt => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Restricted Information',
            message: 'Your dealership has set this information as hidden for your account. Please speak with your dealer if you have questions about this piece of information.'
        });
    }

    const onSetLayerIndex = layerID => {

        let index = layers.findIndex(layer => layer.id === layerID);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers, update(layers, {
            $apply: layers => layers.map(layer => {
                layer.moveToFront = layer.id === layerID;
                return layer;
            })
        }))

        let _index = layerIndex.findIndex(id => id === layerID);
        setLayerIndex(layerIndex, update(layerIndex, {
            $splice: [
                [_index, 1],
                [0, 0, layerID]
            ]
        }))
    }

    const onSetStyleSheetProperties = () => {
        setTheme(window.theme);
        document.body.className = window.theme;
        document.documentElement.style.setProperty('--divider', Appearance.colors.divider());
        document.documentElement.style.setProperty('--green', Appearance.colors.green);
        document.documentElement.style.setProperty('--grey', Appearance.colors.grey());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--theme', window.theme);
        document.querySelector('meta[name="theme-color"]').setAttribute("content", Appearance.colors.background());
    }

    const onShowLeadDetails = async id => {
        try {
            let lead = await Lead.get(utils, id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${id}`,
                permissions: ['leads.details']
            });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSlotChange = slot => {

        // update local state with newly selected slot
        selectedSlot.current = slot;

        // notify subscribers that slot has changed
        utils.events.emit('slot_change', { slot });
    }

    const onUpdateContentComponents = () => {

        const contentHasPermission = entry => {
            if(!entry.permissions) {
                return true;
            }
            let match = entry.permissions.find(key => utils.user.permissions.get(key) === false);
            return match ? false : true;
        }

        // prepare list of targets and filter out content that does not match the user's permissions
        let content = getContent(utils);
        let targets = Object.keys(content).reduce((array, key) => {

            // validate permissions at a section level if applicable
            if(content[key].permissions) {
                if(contentHasPermission(content[key]) === true) {
                    array.push(content[key]);
                }
                return array;
            }

            // loop through panels and determine if panel level validation needs to occur
            if(content[key].panels) {
                let panels = content[key].panels.filter(panel => contentHasPermission(panel)) || [];
                if(panels.length > 0) {
                    array.push({ ...content[key], panels });
                }
                return array;
            }

            // determine if subview panel validation needs to occur
            if(content[key].subViews) {

                let subViews = {};
                Object.keys(content[key].subViews).forEach(view => {

                    // validate permissions subview level if applciable
                    let target = content[key].subViews[view];
                    if(target.permissions && contentHasPermission(target) === false) {
                        return array;
                    }

                    // no additional logic is required if no panels are set
                    if(!target.panels) {
                        subViews[view] = target;
                        return;
                    }

                    // filter out panels that fail the permissions check and remove subview if all panels fail
                    let panels = target.panels.filter(panel => contentHasPermission(panel)) || [];
                    if(panels.length > 0) {
                        subViews[view] = { ...target, panels };
                    }
                });
                array.push({ ...content[key], subViews });
            }
            return array;
            
        }, []);

        // update local state with new content components
        setContent(targets);
    }

    const onUpdateDealershipPreferences = () => {
        utils.events.emit('dealership_preferences_update');
    }

    const onUpdateDemo = props => {
        try {

            // prevent moving forward if event data was not provided
            if(!props.demo) {
                throw new Error('[on_update_demo]: event data not found');
            }

            // update listeners with new target object if applicable
            utils.content.update({
                object: Demo.create(props.demo),
                type: 'demo'
            });

        } catch(e) {
            console.error(`[on_update_demo]: ${e.message}`);
        }
    }

    const onUpdateDemoRequest = props => {
        try {

            // prevent moving forward if event data was not provided
            if(!props.demo_request) {
                throw new Error('[on_update_demo_request]: event data not found');
            }

            // update listeners with new target object if applicable
            utils.content.update({
                object: DemoRequest.create(props.demo_request),
                type: 'demo_request'
            });

        } catch(e) {
            console.error(`[on_update_demo_request]: ${e.message}`);
        }
    }

    const onUpdateEvent = props => {
        try {

            // prevent moving forward if event data was not provided
            if(!props.event) {
                throw new Error('[on_update_event]: event data not found');
            }

            // update listeners with new target object if applicable
            utils.content.update({
                object: Event.create(props.event),
                type: 'event'
            });

        } catch(e) {
            console.error(`[on_update_event]: ${e.message}`);
        }
    }

    const onUpdateGroup = ({ group }) => {
        try {
            console.log(`updated group ${group.id}`);
            if(!groupsRef.current) {
                return;
            }
            setGroups(groupsRef.current.map(prevGroup => {
                return prevGroup.id === group.id ? group : prevGroup;
            }));
        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateLead = props => {
        try {
            console.log(props);
            // prevent moving forward if event data was not provided
            if(!props.lead) {
                throw new Error('[on_update_lead]: event data not found');
            }

            // update listeners with new target object if applicable
            utils.content.update({
                object: Lead.create(props.lead),
                type: 'lead'
            });

        } catch(e) {
            console.error(`[on_update_lead]: ${e.message}`);
        }
    }

    const onUpdateTheme = evt => {
        window.theme = evt.matches ? 'dark' : 'light';
        onSetStyleSheetProperties();
        setNonce(moment().unix());
    }

    const onWindowSizeChange = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    const getPanels = () => {

        // prevent moving forward if no primary view is set
        let { view, subView } = active;
        if(!view) {
            return [];
        }

        // prepare an array of the requested panels
        return content.reduce((array, entry) => {

            // skip entry if view doesnt match
            if(entry.key !== view) {
                return array;
            }

            // determine if the requested view contains subviews
            if(subView) {
                return entry.subViews[subView].panels.filter(panel => panel.visible !== false);
            }
            
            // fallback to returning all panels for selected view
            return entry.panels.filter(panel => panel.visible !== false);

        }, []);
    }

    const getRestrictedText = () => {
        return (
            <img
            className={'text-button'}
            onClick={onRestrictedTextClick}
            src={'images/text-content-hidden.png'}
            style={{
                width: 70,
                height: 20,
                objectFit: 'contain'
            }} />
        )
    }

    const getHeaderItems = () => {

        // prevent moving forward if an overview object is not available
        if(!overview) {
            return null;
        }

        // prepare overview items
        let items = [{
            key: 'total',
            title: 'Total Demos',
            value: overview.total,
            color: Appearance.colors.darkGrey
        },{
            key: 'requested',
            title: `Requested Demos`,
            value: overview.requested,
            color: Appearance.colors.lightGrey
        },{
            key: 'confirmed',
            title: `Confirmed Demos`,
            value: overview.confirmed,
            color: Demo.styles.colorForStatus(Demo.status.get().confirmed)
        },{
            key: 'rescheduled',
            title: `Rescheduled Demos`,
            value: overview.rescheduled,
            color: Demo.styles.colorForStatus(Demo.status.get().rescheduled)
        },{
            key: 'ran',
            title: `Ran Demos`,
            value: overview.ran,
            color: Demo.styles.colorForStatus(Demo.status.get().set)
        },{
            key: 'sale',
            title: 'Sold Demos',
            value: overview.sale,
            color: Demo.styles.colorForStatus(Demo.status.get().sale)
        }];

        return (
            <div>
                {content.length !== 0 && (
                    <DealershipOverview 
                    onDateChange={dates => {
                        overviewProps.current.end_date = dates.end;
                        overviewProps.current.start_date = dates.start;
                        fetchOverview();
                    }} 
                    onSlotChange={onSlotChange}
                    utils={utils} />
                )}
                <div className={'row p-2 m-0'}>
                    {items.map((item, index) => {
                        return content.length > 0 && (
                            <OverviewItem
                            count={overview[item.key]}
                            endDate={overviewProps.current.end_date}
                            index={index}
                            item={item}
                            key={index}
                            loading={loading === 'overview'} 
                            onLoadingChange={setLoading}
                            startDate={overviewProps.current.start_date}
                            utils={utils}/>
                        )
                    })}
                </div>
            </div>
        )
    }

    const getMobileHeader = () => {

        return (
            <nav className={`main-navbar ${theme} navbar navbar-light sticky-top d-flex d-md-none flex-md-nowrap p-0 w-100 text-center`}>
                <div style={{
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <a
                    href={'#'}
                    className={'nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center'}>
                        <img
                        className={'text-button'}
                        src={`images/navigation-${theme === 'dark' ? 'white' : 'dark-grey'}.png`}
                        style={{
                            width: 25,
                            height: 25,
                            objectFit: 'contain'
                        }}
                        onClick={() => setSidebar(0)} />
                    </a>
                    <div className={'nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center'}/>
                </div>
            </nav>
        )
    }

    const getLayers = () => {
        return layers.map(({ abstract, id, onMount, options, target, title, visible, Component }, index) => {
            if(visible === false) {
                // must return null instead of using filter
                // using filter for visible does not preseve other visible layers
                return null;
            }
            return (
                <Component
                abstract={abstract}
                index={index}
                key={index}
                title={title}
                utils={utils}
                options={{
                    ...options,
                    index: index,
                    zIndex: EndIndex - layerIndex.findIndex(indexID => id === indexID),
                    onClose: onCloseLayer,
                    onLayerIndexChange: onSetLayerIndex,
                    onMount: onMount,
                    onReposition: onLayerReposition,
                    target: target
                }}/>
            )
        });
    }

    const getMainContent = () => {

        let panels = getPanels();
        return (
            <>
            {getLayers()}
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <VelocityComponent
                    easing={[250, 20]}
                    duration={750}
                    delay={250}
                    animation={{
                        left: sidebar
                    }}>
                        <aside
                        className={'main-sidebar col-12 col-md-3 col-lg-2 px-0'}
                        style={{
                            borderWidth: 0,
                            zIndex: 1000
                        }}>
                            <Sidebar
                            active={active}
                            activeDealership={activeDealership}
                            content={content}
                            onClick={onNavigationChange} 
                            onLogoutClick={onRequestLogout}
                            onMobileClose={() => setSidebar(-window.innerWidth)}
                            permissions={permissions}
                            user={user}
                            utils={utils}/>
                        </aside>
                    </VelocityComponent>

                    <main
                    className={'main-content col-sm-12 col-md-9 col-lg-10 p-0 offset-lg-2 offset-md-3'}
                    style={{
                        zIndex: '900'
                    }}>
                        {getMobileHeader()}
                        <animated.div
                        className={'main-content-container container-fluid px-0 pt-0'}
                        style={{
                            position: 'relative',
                            paddingBottom: 250,
                            ...container
                        }}>
                            {getHeaderItems()}
                            <div
                            className={'row w-100 p-0 m-0'}
                            id={'panel-container'}
                            style={{
                                position: 'relative'
                            }}>
                                <DndProvider
                                backend={HTML5Backend}
                                options={{
                                    enableTouchEvents: false,
                                    enableMouseEvents: true
                                }}>
                                    {panels && panels.map(({ Component }, index) => (
                                        <Component
                                        key={index}
                                        utils={utils}
                                        index={index} />
                                    ))}
                                </DndProvider>
                            </div>
                        </animated.div>
                    </main>
                </div>
            </div>
            </>
        )
    }

    const connectToSockets = async user => {
        try {

            // setup sockets and listeners
            await SocketManager.connect(user);

            // cross platform communication
            SocketManager.persistOn('aft', `is_active_${user.user_id}`, onAFTActiveRequest);
            SocketManager.persistOn('aft', `on_show_${user.user_id}`, onShowAFTContent);

            // demo change listeners
            SocketManager.persistOn('leads', 'on_demo_status_change', onDemoStatusChange);
            SocketManager.persistOn('demos', 'on_new_demo', onNewDemo);
            SocketManager.persistOn('demos', 'on_update_demo', onUpdateDemo);

            // demo request change listeners
            SocketManager.persistOn('leads', 'on_demo_request_status_change', onDemoRequestStatusChange);
            SocketManager.persistOn('demos', 'on_new_demo_request', onNewDemoRequest);
            SocketManager.persistOn('demos', 'on_update_demo_request', onUpdateDemoRequest);

            // notifications for specific user and account types
            SocketManager.persistOn('notifications', `on_notification_${user.user_id}`, onNotification);
            SocketManager.persistOn('notifications', `on_notification_level_${user.level}`, onNotification);

            // event change listeners
            SocketManager.persistOn('events', 'on_new_event', onNewEvent);
            SocketManager.persistOn('events', 'on_update_event', onUpdateEvent);

            // lead change listeners
            SocketManager.persistOn('leads', 'on_new_lead', onNewLead);
            SocketManager.persistOn('leads', 'on_status_change', onLeadStatusChange);
            SocketManager.persistOn('leads', 'on_update_lead', onUpdateLead);

            // user groups by on user id
            SocketManager.persistOn('users', `on_new_group_${user.user_id}`, onNewGroup);
            SocketManager.persistOn('users', `on_update_group_${user.user_id}`, onUpdateGroup);
            SocketManager.persistOn('users', `on_remove_group_${user.user_id}`, onRemoveGroup);

            // user group listeners based on account type
            SocketManager.persistOn('users', `on_new_group_level_${user.level}`, onNewGroup);
            SocketManager.persistOn('users', `on_update_group_level_${user.level}`, onUpdateGroup);
            SocketManager.persistOn('users', `on_remove_group_level_${user.level}`, onRemoveGroup);

            // user permission changes
            SocketManager.persistOn('users', `on_permissions_change_${user.user_id}`, onPermissionsChange);

            // update root flag for websocket connection
            setSocketsConnected(true);

        } catch(e) {
            console.error(`[websockets]: ${e.message}`);
        }
    }

    const fetchDealershipStatusCodes = async () => {
        try {
            let { status_codes } = await Request.get(utils, '/dealerships/', {
                show_global: true,
                type: 'status_codes'
            });
            setStatusCodes(status_codes.map(status => Status.create(status)));
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the status codes for this dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchOverview = async () => {
        try {
            if(!dealershipRef.current || !userRef.current) {
                return;
            }

            setLoading('overview');
            let response = await Request.get(utils, '/utils/', {
                end_date: overviewProps.current.end_date.format('YYYY-MM-DD'),
                start_date: overviewProps.current.start_date.format('YYYY-MM-DD'),
                type: 'overview'
            });

            setLoading(false);
            setOverview(response);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue fetching some of the information needed for setup. ${e.message || 'An unknown error occurred'}`
            });
        }
    };

    const findContent = () => {

        // return main application content if a user state has been set
        if(user) {
            return getMainContent();
        }

        // determine if pre-login tasks have completed
        if(preflight === false) {
            return null;
        }

        // fallback to showing user login
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                padding: 25,
                width: '100%'
            }}>
                <Login
                onLogin={onLogin}
                utils={utils}/>

                {getLayers()}

                {requestAgreement && (
                    <RequestAgreement
                    {...requestAgreement}
                    utils={utils}
                    onClose={() => {
                        setRequestAgreement(null);
                        if(typeof(requestAgreement.onClose) === 'function') {
                            requestAgreement.onClose();
                        }
                    }}/>
                )}
            </div>
        )
    }

    const runPreflightTasks = async () => {
        try {

            // run pre-login tasks  if applicable
            let parameters = QueryString.parse(window.location.search);
            switch(parameters.route) {
                case 'admin_login_as_user':
                case 'single_sign_on':
                return onLoginAsUser(parameters.token);
            }

            // fetch and decode cross-platform cookie
            let preferences = utils.graci.preferences.get();

            // determine if a token is present and auto login is enabled for the platform
            if(preferences.refresh_token && preferences.auto_login === true) {

                // set loading flag and run login for access token
                await utils.loader.show();
                let result = await runLogin(utils, { refresh_token: preferences.refresh_token });

                // set user manually so login ui doesn't render after preflight flag is updated
                setUser(result.user);
                
                // run post-login logic
                onLogin(result);
            }

            // set preflight flag as complete
            setPreflight(true);

        } catch(e) {
            console.error(e.message);
            setPreflight(true);
            utils.loader.hide();
        }
    }

    const utils = {
        alert: {
            aft: props => {
                utils.alert.show({
                    ...props,
                    buttons: [{
                        key: 'aft',
                        title: 'Open AFT',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'aft') {
                            window.open('https://aftplatform.com');
                        }
                    }
                });
            },
            show: async props => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }
                    setAlerts(alerts => update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            ...props
                        }]
                    }))
                } catch(e) {
                    console.error(e.message)
                }
            },
            showAsync: async props => {
                return new Promise(async (resolve, reject) => {
                    try {
                        if(loader) {
                            await utils.loader.hide();
                        }
                        setAlerts(alerts => update(alerts, {
                            $push: [{
                                id: `${moment().unix()}-${Math.random()}`,
                                onClose: resolve,
                                ...props
                            }]
                        }))
                    } catch(e) {
                        reject(e.message)
                    }
                })
            },
            dev: async () => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }
                    setAlerts(alerts => update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            title: 'In Development',
                            message: 'This feature is currently under development and will become available at a later date'
                        }]
                    }))

                } catch(e) {
                    console.error(e.message)
                }
            },
        },
        agreement: {
            request: props => setRequestAgreement(props)
        },
        api: {
            headers: () => {
                return {
                    'Content-Type': 'application/json',
                    'X-API': `Version ${API.version}`,
                    'X-Timezone': `TZ ${moment.tz.guess()}`,
                    'X-Web': `Build ${API.build}`,
                    ...userRef.current && {
                        'Authorization': `Bearer ${userRef.current.token}`,
                        'Identification': `User ${userRef.current.user_id}`
                    }
                }
            }
        },
        content: ContentManager,
        datePicker: {
            show: props => {
                if(props.overrideAlerts) {
                    setDatePickerOverride({
                        utils: utils,
                        id: `${moment().unix()}-${Math.random()}`,
                        ...props
                    });
                    return;
                }
                setDatePicker({
                    utils: utils,
                    id: `${moment().unix()}-${Math.random()}`,
                    ...props
                })
            },
            showDual: props => {
                utils.layer.open({
                    id: 'dual_date_picker_alert',
                    Component: DualDatePicker.bind(this, {
                        ...props,
                        utils: utils
                    })
                })
            }
        },
        dealership: {
            get: () => dealershipRef.current,
            set: async dealership => {
                try {

                    // loop through namespaces and leave dealership room
                    utils.sockets.namespaces.forEach(namespace => {
                        utils.sockets.persistEmit(namespace, 'leave', { dealership_id: activeDealership.id });
                    });
                    
                    // remove subscriptions for dealership preference changes and new notifications
                    await utils.sockets.off('dealerships', 'on_update_preferences', onUpdateDealershipPreferences);
                    await utils.sockets.off('notifications', `on_notification_${userRef.current.user_id}`, onNotification);
                    await utils.sockets.off('notifications', `on_notification_level_${userRef.current.level}`, onNotification);

                    // update local state for active dealership
                    dealershipRef.current = dealership;
                    setActiveDealership(dealership);
                    
                } catch(e) {
                    console.error(e.message);
                }
            },
            slot: {
                get: () => selectedSlot.current
            },
            status_codes: {
                fetch: fetchDealershipStatusCodes,
                get: () => statusCodesRef.current
            }
        },
        events: EventsManager,
        fetching: {
            get: key => {
                return fetching.current[key];
            },
            set: (key, value) => {
                fetching.current[key] = value;
            }
        },
        graci: {
            preferences: {
                get: () => {
                    let result = Cookies.get('auto_login_preferences');
                    return typeof(result) === 'string' ? JSON.parse(result) : {};
                },
                set: props => {
                    Cookies.set('auto_login_preferences', JSON.stringify(props));
                }
            }
        },
        groups: {
            get: () => groupsRef.current,
            apply: (key, group, value) => {
                if(Array.isArray(key)) {
                    for(var i in key) {
                        if(utils.groups.check(group, key[i]) === false) {
                            return getRestrictedText();
                        }
                    }
                    return value;
                }
                if(utils.groups.check(group, key) === false) {
                    return getRestrictedText();
                }
                return value;
            },
            check: (category, type) => {
                if(!groupsRef.current || groupsRef.current.length === 0) {
                    return true;
                }
                let matches = groupsRef.current.filter(prevGroup => prevGroup.category.code === category);
                for(var i in matches) {
                    if(matches[i].props && matches[i].props[type] === false) {
                        return false;
                    }
                }
                return true;
            }
        },
        layer: {
            open: layer => onOpenLayer(layer),
            openMultiple: layers => onOpenLayerMultiple(layers),
            close: layer => onCloseLayer(layer),
            web: props => onOpenLayer({
                id: props.id,
                Component: WebView.bind(this, props)
            }),
            requestClose: id => {
                utils.events.emit('layer_state_change_request', { 
                    id: id,
                    value: 'close'
                });
            }
        },
        loader: {
            show: async () => {
                return new Promise(resolve => {
                    setLoader(true);
                    setTimeout(resolve, 500)
                })
            },
            hide: async () => {
                return new Promise(resolve => {
                    setLoader(false);
                    setTimeout(resolve, 500)
                })
            }
        },
        notification: {
            show: props => {
                ContentManager.fetch('notifications');
                NotificationCenter.notify(utils, props);
                setNotification(props);
            }
        },
        sheet: {
            show: (sheet, callback) => {
                setSheet({ ...sheet, onClick: callback, utils })
            }
        },
        sockets: SocketManager,
        stripe: Stripe,
        user: {
            get: () => userRef.current,
            permissions: {
                alert: {
                    
                },
                get: key => {
                    return permissions.values && typeof(permissions.values[key]) === 'boolean' ? permissions.values[key] : true;
                },
                reject: (options = {}) => {

                    // prepare rejection message
                    let message = `This feature has been disabled for your account. ${userRef.current.level > User.levels.get().dealer ? 'Please speak with your dealer if you have any questions.' : 'Please contact Home Office if you have any questions.'}`;

                    // determine if a throwable was requested
                    if(options.throw === true) {
                        throw new Error(message);
                    }

                    // fallback to showing rejection alert
                    utils.alert.show({
                        title: 'Just a Second',
                        message: message
                    });
                }
            }
        }
    }

    useEffect(() => {
        onActiveDealershipChange();
    }, [activeDealership, socketsConnected]);

    useEffect(() => {
        groupsRef.current = groups;
    }, [groups]);

    useEffect(() => {
        userRef.current = user;
    }, [user]);

    useEffect(() => {
        layersRef.current = layers;
    }, [layers]);

    useEffect(() => {
        if(permissions) {
            onUpdateContentComponents();
        }
    }, [permissions]);

    useEffect(() => {
        statusCodesRef.current = statusCodes;
    }, [statusCodes]);

    useEffect(() => {

        runPreflightTasks();

        // setup scroll polyfill and window listeners
        smoothscroll.polyfill();
        window.addEventListener('resize', onWindowSizeChange);
        window.addEventListener('beforeunload', (e) => {
            (e || window.event).returnValue = null;
            return null;
        });

        // css variables
        document.documentElement.style.setProperty('--primary', Appearance.colors.primary());
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());

        // theme and theme listeners
        window.theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.style.setProperty('--theme', window.theme);
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', onUpdateTheme);
        onSetStyleSheetProperties();

        // window state listeners
        window.addEventListener('focus', () => {
            document.title = 'Global Data';
        });

    }, []);

    return (
        <div
        className={`root-container ${window.theme}`}
        nonce={nonce}
        style={{
            height: size.height,
            position: 'relative',
            width: '100%'
        }}>
            {findContent()}
            <Loader animate={loader}/>

            {datePicker && (
                <DatePicker
                {...datePicker}
                onClose={() => {
                    setDatePicker(null);
                    if(typeof(datePicker.onClose) === 'function') {
                        datePicker.onClose();
                    }
                }} />
            )}
            {sheet && (
                <Sheet
                {...sheet}
                onClose={setSheet}/>
            )}
            {notification && (
                <DesktopNotification
                utils={utils}
                notification={notification}
                onClose={setNotification}/>
            )}

            <AlertStack>
                {alerts.map((alert, index) => (
                    <Alert
                    {...alert}
                    key={index}
                    utils={utils}
                    index={(alerts.length - 1) - index}
                    onClose={id => {
                        if(typeof(alert.onClose) === 'function') {
                            alert.onClose();
                        }
                        setAlerts(alerts => {
                            return alerts.filter(alert => {
                                return id !== alert.id;
                            });
                        })
                    }} />
                ))}
            </AlertStack>

            {datePickerOverride && (
                <DatePicker
                {...datePickerOverride}
                onClose={id => {
                    setDatePickerOverride(null);
                    if(typeof(datePickerOverride.onClose) === 'function') {
                        datePickerOverride.onClose();
                    }
                }} />
            )}
        </div>
    )
}

export default App;
