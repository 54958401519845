import React, { useRef, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';

import Appearance from 'styles/Appearance.js';
import LottieView from 'views/Lottie.js';
import Utils from 'files/Utils.js';

const Button = ({ color, label, loading, onClick, permissions, style, transition, type, utils }) => {

    const buttonRef = useRef(null);

    const [hoverState, setHoverState] = useState(false);
    const [buttonAnimations, setButtonAnimations] = useSpring(() => ({
        transform: 'scale(1)'
    }))
    const [iconAnimations, setIconAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 20 },
        left: -350,
        opacity: 0
    }))
    const [labelAnimations, setLabelAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 20 },
        left: 0,
        opacity: 1
    }))

    const onButtonClick = () => {

        // prevent moving forward if feature is disabled for current user
        if(permissions) {
            let match = permissions.find(key => utils.user.permissions.get(key) === false);
            if(match) {
                return utils.user.permissions.reject();
            }
        }

        onMouseLeave();
        if(typeof(onClick) === 'function') {
            onClick({ target: buttonRef.current });
        }
    }

    const onMouseEnter = () => {
        setHoverState(true);
        if(type === 'transition') {
            setButtonAnimations({
                transform: 'scale(1.1)'
            });
            setIconAnimations({
                left: 0,
                opacity: 1
            });
            setLabelAnimations({
                left: 250,
                opacity: 0
            });
        }
    }

    const onMouseLeave = () => {
        setHoverState(false);
        if(type === 'transition') {
            setButtonAnimations({
                transform: 'scale(1)'
            });
            setIconAnimations({
                left: -350,
                opacity: 0
            });
            setLabelAnimations({
                left: 0,
                opacity: 1
            });
        }
    }

    const getColors = () => {
        if(hoverState) {
            switch(color) {
                case 'tertiary':
                return Utils.adjustColor(Appearance.colors.tertiary(), -35);

                case 'secondary':
                return Utils.adjustColor(Appearance.colors.secondary(), -35);

                case 'light':
                return Utils.adjustColor(Appearance.colors.lightGrey, -35);

                case 'dark':
                return Utils.adjustColor(Appearance.colors.darkGrey, -35);

                case 'danger':
                return Utils.adjustColor(Appearance.colors.red, -35);

                case 'primary':
                return Utils.adjustColor(Appearance.colors.primary(), -35);

                default:
                return Utils.adjustColor(color, -35);
            }
        }
        switch(color) {
            case 'tertiary':
            return Appearance.colors.tertiary();

            case 'secondary':
            return Appearance.colors.secondary();

            case 'light':
            return Appearance.colors.lightGrey;

            case 'dark':
            return Appearance.colors.darkGrey;

            case 'danger':
            return Appearance.colors.red;

            case 'primary':
            return Appearance.colors.primary();

            default:
            return color;
        }
    }

    const getContent = () => {
        if(transition) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 30,
                    opacity: loading === true ? 0 : 1,
                    position: 'relative',
                    textAlign: 'center',
                    width: '100%',
                }}>
                    <animated.div style={{
                        position: 'absolute',
                        width: '100%',
                        ...iconAnimations
                    }}>
                        <img
                        src={`images/${transition.icon}`}
                        style={{
                            width: 25,
                            height: 25,
                            objectFit: 'contain'
                        }} />
                    </animated.div>
                    <animated.div style={{
                        position: 'relative',
                        width: '100%',
                        ...labelAnimations
                    }}>
                        <span>{label}</span>
                    </animated.div>
                </div>
            )
        }
        return (
            <span style={{
                display: 'block',
                maxWidth: '100%',
                opacity: loading === true ? 0 : 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }}>{label}</span>
        )
    }

    const getStyles = () => {

        // prepare base button styles
        let styles = {
            ...style,
            backgroundColor: getColors(),
            color: 'white',
            fontSize: '10px',
            fontWeight: 600,
            minWidth: 0,
            padding: '3px 8px 3px 8px',
            whiteSpace: 'normal'
        };

        // loop through button types and determine 
        switch(type) {

            case 'transition':
            return {
                ...styles,
                fontSize: 13,
                fontWeight: 600,
                height: 30,
                padding: '8px 25px 8px 25px',
                width: '100%'
            }

            case 'large':
            return {
                ...styles,
                fontSize: 13,
                fontWeight: 600,
                height: 30,
                padding: '6px 12px 6px 12px',
                width: '100%'
            }

            case 'small':
            return {
                ...styles,
                height: 19,
                fontSize: 10,
                padding: '3px 8px 3px 8px'
            }

            default:
            return styles;
        }
    }

    return (
        <animated.div style={buttonAnimations}>
            <button
            onClick={onButtonClick}
            className={'btn btn-pill'}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={buttonRef}
            style={{
                ...getStyles(),
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                overflow: 'hidden',
                position: 'relative'
            }}>
                {getContent()}
                {loading === true && (
                    <div style={{
                        position: 'absolute'
                    }}>
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={require('files/lottie/dots-white.json')}
                        style={{
                            height: 25,
                            width: 25
                        }}/>
                    </div>
                )}
            </button>
        </animated.div>
    )
}
export default Button;
