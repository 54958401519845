export const api_version = 2;
export const app_version = '2.1';
export const beta_env = false;
export const build = '2024.08.06.12.55';
export const dev_env = false;
export const dev_marketing_director = false;
export const dev_safety_advisor = false;
export const server = dev_env ? 'http://globaldata.local.192.168.4.40.nip.io' : 'https://globaldata.link';

export default {
    aft_server: dev_env ? 'http://aft.local.192.168.4.40.nip.io' : 'https://aftplatform.com',
    apps: {
        android: 'https://play.google.com/store/apps/details?id=com.ghs.globaldata',
        ios: 'https://apps.apple.com/us/app/global-data-by-ghs/id1554941899'
    },
    app_version: app_version,
    beta_env: beta_env,
    build: build,
    change_notes: null,
    dev_env: dev_env,
    dev_marketing_director: dev_marketing_director,
    dev_safety_advisor: dev_safety_advisor,
    version: api_version,
    server: server,
    url: `${server}/api/${beta_env ? 'beta' : `v${api_version}`}`,
    sockets: dev_env ? `${server}:4000` : `https://sockets.globaldata.link`,
    website: 'https://ghscompanies.com',
    mapbox: 'pk.eyJ1IjoiZ2hzLXJpZGEiLCJhIjoiY2tpcTU0ZW9hMGQzYTJybXYxcGU5NHRrdSJ9.-KPLGweAXcpYfrJJoUB5NA',
    stripe: dev_env ? 'pk_test_51IXdy9G6RZW42dLL51H4CKaukROsRhXUgs9AnLmQgJOiE3R6ZK8LPM7inn6KUBuHET0o8PVhkDNAHyD1Zgtna67a00N5YaAIrD' : 'pk_live_51IXdy9G6RZW42dLLNWUYUavOUd7i4I8XmTwO819lk1cvXHLsQYPEz8nPQHqa0FE9HFiEWLJeP8PRyGtYpRgwaN5g00YlzPewN3'
}
