import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import Dealership from 'classes/Dealership.js';
import Program from 'classes/Program.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class LeadClass {

    active = null;
    address = null;
    affiliate = null;
    assignment_user = null;
    created = null;
    customer_response = null;
    dealership = null;
    dealership_id = null;
    email_address = null;
    enrollment_user = null;
    extended_details = null;
    first_name = null;
    full_name = null;
    homeowner_status = null;
    id = null;
    last_name = null;
    lead_script = null;
    lead_sub_type = null;
    lead_type = null;
    location = null;
    marital_status = null;
    marketing_director_user = null;
    notes = null;
    occupational_status = null;
    opportunity = null;
    out_of_service_area = false;
    phone_number = null;
    priority = null;
    program_credit = null;
    released = null;
    sale_date = null;
    spouse_email_address = null;
    spouse_first_name = null;
    spouse_last_name = null;
    spouse_phone_number = null;
    static_map_image_url = null;
    status = null;
    tags = null;
    tag_ids = null;
    timezone = null;
    transfer = null;
    unstructured_content_id = null;
    user = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.address = props.address;
        this.affiliate = props.affiliate && new LeadClass().create(props.affiliate);
        this.assignment_user = props.assignment_user && User.create(props.assignment_user);
        this.created = props.created && moment.utc(props.created).local();
        this.customer_response = props.customer_response && new CustomerResponseClass().create(props.customer_response);
        this.dealership_id = props.dealership_id;
        this.email_address = props.email_address;
        this.enrollment_user = props.enrollment_user && User.create(props.enrollment_user);
        this.extended_details = props.extended_details;
        this.first_name = props.first_name;
        this.full_name = props.full_name;
        this.homeowner_status = props.homeowner_status;
        this.id = props.id;
        this.last_name = props.last_name;
        this.lead_script = props.lead_script;
        this.lead_sub_type = props.lead_sub_type;
        this.lead_type = props.lead_type;
        this.location = props.location && {
            latitude: props.location.lat || props.location.latitude,
            longitude: props.location.long || props.location.longitude
        };
        this.marital_status = props.marital_status;
        this.marketing_director_user = props.marketing_director_user && User.create(props.marketing_director_user);
        this.notes = props.notes;
        this.occupational_status = props.occupational_status;
        this.opportunity = props.opportunity;
        this.out_of_service_area = props.out_of_service_area;
        this.phone_number = props.phone_number;
        this.program_credit = props.program_credit;
        this.priority = props.priority;
        this.released = props.released;
        this.sale_date = props.sale_date && moment.utc(props.sale_date).local();
        this.spouse_email_address = props.spouse_email_address;
        this.spouse_first_name = props.spouse_first_name;
        this.spouse_last_name = props.spouse_last_name;
        this.spouse_phone_number = props.spouse_phone_number;
        this.static_map_image_url = props.static_map_image_url;
        this.status = props.status;
        this.tag_ids = props.tags;
        this.timezone = props.timezone;
        this.transfer = props.transfer;
        this.unread_sms = props.unread_sms;
        this.unstructured_content_id = props.unstructured_content_id;
        this.user = props.user;
        return this;
    }

    open = () => {
        this.edits = {
            address: this.address,
            affiliate: this.affiliate,
            assignment_user: this.assignment_user,
            dealership: this.dealership,
            email_address: this.email_address,
            enrollment_user: this.enrollment_user,
            first_name: this.first_name,
            full_name: this.full_name,
            homeowner_status: this.homeowner_status,
            last_name: this.last_name,
            lead_script: this.lead_script,
            lead_sub_type: this.lead_sub_type,
            lead_type: this.lead_type,
            location: this.location,
            marital_status: this.marital_status,
            marketing_director_user: this.marketing_director_user,
            notes: this.notes,
            occupational_status: this.occupational_status,
            out_of_service_area: this.out_of_service_area,
            phone_number: this.phone_number,
            priority: this.priority,
            program_credit: this.program_credit,
            spouse_email_address: this.spouse_email_address,
            spouse_first_name: this.spouse_first_name,
            spouse_last_name: this.spouse_last_name,
            spouse_phone_number: this.spouse_phone_number,
            tags: this.tags,
            user: this.user
        }
        return this.edits;
    }

    set = props => {
        
        // set default edit values
        this.edits = {
            ...this.edits,
            ...props
        }

        // concatenate full name if applicable
        if(this.edits.first_name && this.edits.last_name) {
            this.edits.full_name = `${this.edits.first_name} ${this.edits.last_name}`;
        }

        // attach lead script from lead type if applicable
        if(props.lead_type && props.lead_type.lead_script) {
            this.edits.lead_script = props.lead_type.lead_script;
        }
        return this.edits;
    }

    close = () => {
        this.address = this.edits.address;
        this.affiliate = this.edits.affiliate;
        this.assignment_user = this.edits.assignment_user;
        this.dealership = this.edits.dealership;
        this.email_address = this.edits.email_address;
        this.enrollment_user = this.edits.enrollment_user;
        this.first_name = this.edits.first_name;
        this.full_name = this.edits.full_name;
        this.homeowner_status = this.edits.homeowner_status;
        this.last_name = this.edits.last_name;
        this.lead_script = this.edits.lead_script;
        this.lead_sub_type = this.edits.lead_sub_type;
        this.lead_type = this.edits.lead_type;
        this.location = this.edits.location;
        this.marital_status = this.edits.marital_status;
        this.marketing_director_user = this.edits.marketing_director_user;
        this.notes = this.edits.notes;
        this.occupational_status = this.edits.occupational_status;
        this.out_of_service_area = this.edits.out_of_service_area;
        this.phone_number = this.edits.phone_number;
        this.priority = this.edits.priority;
        this.program_credit = this.edits.program_credit;
        this.spouse_email_address = this.edits.spouse_email_address;
        this.spouse_first_name = this.edits.spouse_first_name;
        this.spouse_last_name = this.edits.spouse_last_name;
        this.spouse_phone_number = this.edits.spouse_phone_number;
        this.tags = this.edits.tags;
        this.user = this.edits.user;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/leads/', {
                    type: 'new',
                    ...this.toJSON(this.edits),
                    ...props
                })

                this.id = id;
                this.close();
                utils.content.fetch('lead');

                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async (utils, props = {}) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { static_map_image_url, status } = await Request.post(utils, '/leads/', {
                    type: 'update',
                    id: this.id,
                    ...this.toJSON(this.edits),
                    ...props,
                    ...props && props.affiliate && {
                        affiliate: props.affiliate.id
                    }
                })

                this.close();
                this.notes = props.notes || this.notes;
                this.static_map_image_url = static_map_image_url;
                this.status = status;

                utils.content.fetch('demo');
                utils.content.update({
                    type: 'lead',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            address: target.address,
            affiliate: target.affiliate ? target.affiliate.id : null,
            assignment_user_id: target.assignment_user ? target.assignment_user.user_id : null,
            dealership_id: target.dealership ? target.dealership.id : null,
            email_address: target.email_address,
            enrollment_user_id: target.enrollment_user ? target.enrollment_user.user_id : null,
            first_name: target.first_name,
            last_name: target.last_name,
            lead_script_id: target.lead_script ? target.lead_script.id : null,
            lead_sub_type: target.lead_sub_type ? target.lead_sub_type.id : null,
            lead_type: target.lead_type ? target.lead_type.id : null,
            location: target.location,
            homeowner_status: target.homeowner_status ? target.homeowner_status.code : null,
            id: this.id,
            marital_status: target.marital_status ? target.marital_status.code : null,
            marketing_director_user_id: target.marketing_director_user ? target.marketing_director_user.user_id : null,
            notes: target.notes,
            occupational_status: target.occupational_status ? target.occupational_status.code : null,
            out_of_service_area: target.out_of_service_area,
            phone_number: target.phone_number,
            priority: target.priority,
            program_credit: target.program_credit ? target.program_credit.id : null,
            spouse_email_address: target.spouse_email_address,
            spouse_first_name: target.spouse_first_name,
            spouse_last_name: target.spouse_last_name,
            spouse_phone_number: target.spouse_phone_number,
            tags: target.tags ? target.tags.map(tag => tag.id) : null,
            user_id: target.user ? target.user.user_id : null
        }
    }
}

class LeadScriptClass {

    active = null;
    date = null;
    dealership = null;
    dealership_id = null;
    id = null;
    is_default = null;
    text = null;
    title = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.date = props.date && moment(props.date);
        this.dealership_id = props.dealership_id;
        this.id = props.id;
        this.is_default = props.is_default;
        this.text = props.text;
        this.title = props.title;
        return this;
    }

    open = () => {
        this.edits = {
            dealership: this.dealership,
            text: this.text,
            title: this.title
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.dealership = this.edits.dealership;
        this.text = this.edits.text;
        this.title = this.edits.title;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/dealerships/', {
                    type: 'new_lead_script',
                    ...this.toJSON(this.edits)
                })

                this.id = id;
                this.close();
                utils.content.fetch('lead_script');

                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/dealerships/', {
                    type: 'update_lead_script',
                    ...this.toJSON(this.edits)
                })

                this.close();
                utils.content.update({
                    type: 'lead_script',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            dealership_id: target.dealership && target.dealership.id,
            id: this.id,
            is_default: target.is_default,
            text: target.text,
            title: target.title
        }
    }
}

class LeadScriptRebuttalClass {

    active = null;
    content = null;
    date = null;
    dealership_id = null;
    id = null;
    title = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.content = props.content;
        this.date = props.date && moment(props.date);
        this.dealership_id = props.dealership_id;
        this.id = props.id;
        this.title = props.title;
        return this;
    }

    open = () => {
        this.edits = {
            content: this.content,
            dealership: this.dealership,
            title: this.title,
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.content = this.edits.content;
        this.dealership = this.edits.dealership;
        this.title = this.edits.title;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/dealerships/', {
                    type: 'new_lead_script_rebuttal',
                    ...this.toJSON(this.edits)
                })

                this.id = id;
                this.close();
                utils.content.fetch('lead_script_rebuttal');

                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/dealerships/', {
                    type: 'update_lead_script_rebuttal',
                    id: this.id,
                    ...this.toJSON(this.edits)
                })

                this.close();
                utils.content.update({
                    type: 'lead_script_rebuttal',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            id: this.id,
            dealership_id: target.dealership ? target.dealership.id : null,
            title: target.title,
            content: target.content
        }
    }
}

class LeadSubTypeClass {

    active = null;
    date = null;
    dealership_id = null;
    id = null;
    is_default = false;
    text = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    close = async () => {
        this.text = this.edits.text;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.date = props.date && moment(props.date);
        this.dealership_id = props.dealership_id;
        this.id = props.id;
        this.is_default = props.is_default;
        this.text = props.text;
        return this;
    }

    open = async () => {
        this.edits = {
            text: this.text
        }
        return this.edits;
    }

    set = async props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                // submit request to server
                let { id } = await Request.post(utils, '/dealerships/', {
                    type: 'new_lead_type',
                    ...this.toJSON(this.edits),
                    ...props
                });

                // set record id and close editing
                this.id = id;
                this.close();

                // notify subscribers that new lead sub-type data is available
                utils.content.fetch('lead_sub_type');

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    toJSON = props => {
        let target = props || this;
        return {
            id: this.id,
            text: target.text
        }
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {

                // submit request to server
                Request.post(utils, '/dealerships/', {
                    type: 'update_lead_sub_type',
                    id: this.id,
                    ...this.toJSON(this.edits)
                })

                // close editing
                this.close();

                // notify subscribers that new data is available
                utils.content.fetch('lead');
                utils.content.update({
                    type: 'lead_sub_type',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }
}

class LeadTypeClass {

    active = null;
    date = null;
    dealership_id = null;
    icon = null;
    id = null;
    is_default = false;
    lead_script = null;
    text = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.date = props.date ? moment(props.date) : null;
        this.dealership_id = props.dealership_id;
        this.icon = props.icon;
        this.id = props.id;
        this.is_default = props.is_default;
        this.lead_script = props.lead_script ? new LeadScriptClass().create(props.lead_script) : null;
        this.text = props.text;
        return this;
    }

    open = () => {
        this.edits = {
            icon: this.icon,
            lead_script: this.lead_script,
            text: this.text
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = () => {
        this.icon = this.edits.icon;
        this.lead_script = this.edits.lead_script;
        this.text = this.edits.text;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/dealerships/', {
                    type: 'new_lead_type',
                    ...this.toJSON(this.edits),
                    ...props
                })

                this.id = id;
                this.close();
                utils.content.fetch('lead');
                utils.content.fetch('lead_type');
                utils.content.fetch('lead_script');

                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { status } = await Request.post(utils, '/dealerships/', {
                    type: 'update_lead_type',
                    id: this.id,
                    ...this.toJSON(this.edits)
                })

                this.close();
                this.status = status;

                utils.content.fetch('lead');
                utils.content.update({
                    type: 'lead_type',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            icon: target.icon && target.icon.id,
            id: this.id,
            lead_script_id: target.lead_script && target.lead_script.id,
            text: target.text
        }
    }
}

class LeadProgramClass {

    id = null;
    lead_id = null;
    program = null;
    start_date = null;
    end_date = null;
    completed = null;
    funded = null;
    private = null;
    status = null;
    notes = null;
    avatar = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.lead_id = props.lead_id;
        this.program = props.program ? Program.create(props.program) : null;
        this.start_date = props.start_date;
        this.end_date = props.end_date;
        this.completed = props.completed;
        this.funded = props.funded;
        this.private = props.private;
        this.status = props.status;
        this.notes = props.notes;
        this.avatar = props.avatar;
        return this;
    }
}

class MessageClass {

    date = null;
    dealership_id = null;
    id = null;
    lead_id = null;
    phone_number = null;
    sender = null;
    show_date = null;
    show_name = null;
    text = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.date = props.date && moment.utc(props.date).local();
        this.dealership_id = props.dealership_id;
        this.direction = props.direction;
        this.id = props.id;
        this.lead_id = props.lead_id;
        this.phone_number = props.phone_number;
        this.sender = props.sender;
        this.show_date = props.show_date;
        this.show_name = props.show_name;
        this.text = props.text;
        return this;
    }
}

class CustomerResponseClass {

    id = null;
    program_id = null;
    lead_id = null;
    date = null;
    props = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.program_id = props.program_id;
        this.lead_id = props.lead_id;
        this.date = props.date ? moment(props.date) : null;
        this.props = props.props;
        return this;
    }
}

class TransferClass {

    id = null;
    user = null;
    lead = null;
    from_dealership = null;
    to_dealership = null;
    date = null;
    status = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.user = props.user ? User.create(props.user) : null;
        this.lead = props.lead ? new LeadClass().create(props.lead) : null;
        this.from_dealership = props.from_dealership ? Dealership.create(props.from_dealership) : null;
        this.to_dealership = props.to_dealership ? Dealership.create(props.to_dealership) : null;
        this.date = props.date ? moment(props.date) : null;
        this.status = props.status;
        return this;
    }
}

const fetchLead = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { lead } = await Request.get(utils, '/leads/', {
                id: id,
                type: 'details'
            });
            let result = new LeadClass().create(lead);
            resolve(result);

        } catch(e) {
            reject(e);
        }
    })
}

const fetchLeadScript = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { script } = await Request.get(utils, '/leads/', {
                type: 'lead_script_details',
                id: id
            });
            let obj = new LeadScriptClass().create(script);
            resolve(obj);

        } catch(e) {
            reject(e);
        }
    })
}

const getAutoAssignments = target => {

    let presets = {};
    let { assignment_user, enrollment_user, user } = target;

    // automatically set assigned user if applicable
    if(assignment_user && assignment_user.level <= User.levels.get().safety_advisor) {
        presets.assigned = assignment_user;
    } else if(user && user.level <= User.levels.get().safety_advisor) {
        presets.assigned = user;
    }

    // automatically set ride along if applicable
    if(user && user.level === User.levels.get().safety_associate) {
        presets.ride_along = user;
    } else if(enrollment_user && enrollment_user.level === User.levels.get().safety_associate) {
        presets.ride_along = enrollment_user;
    }
    return presets;
}

const statusCodes = {
    pending: -1,
    new: 1,
    set: 100,
    assigned: 125,
    confirmed: 150,
    sale: 200,
    recall: 250,
    do_not_call: 275,
    did_not_sell: 300,
    not_home: 400,
    not_interested: 450,
    incomplete: 500,
    wrong_number: 525,
    left_voicemail: 550,
    rescheduled: 700,
    cancelled: 800,
    turndown: 900,
    unqualified: 1000
};

const staticLeadTypes = {
    care_and_share: 10,
    imported: 11,
    program_registration: 12,
    transfered: 415,
    survey: 2,
    survey_monkey_lead_generation: 911,
    undetermined: 928,
    omnishield_app_referral: 964,
    dealership_website: 997,
    recruiting: 1010
}

export default {
    new: () => new LeadClass(),
    get: (utils, id) => fetchLead(utils, id),
    create: props => new LeadClass().create(props),
    assignments: {
        guess: target => getAutoAssignments(target)
    },
    status: {
        get: () => statusCodes
    },
    filters: {
        administrative_area_level_1: 13,
        assignment_user: 1,
        enrollment_credit: 3,
        homeowner_status: 4,
        lead_credit: 5,
        lead_sub_type: 6,
        lead_type: 7,
        locality: 2,
        marketing_director: 8,
        marital_status: 9,
        occupational_status: 10,
        priority: 11,
        program: 12,
        status: 14,
        survey_credit: 15,
        tags: 16
    },
    Message: {
        create: props => new MessageClass().create(props)
    },
    Program: {
        new: () => new LeadProgramClass(),
        create: props => new LeadProgramClass().create(props),
        status: {
            in_progress: 1,
            completed: 2,
            funded: 3,
            revoked: 4
        }
    },
    Script: {
        get: (utils, id) => fetchLeadScript(utils, id),
        new: () => new LeadScriptClass(),
        create: props => new LeadScriptClass().create(props),
        Rebuttal: {
            new: () => new LeadScriptRebuttalClass(),
            create: props => new LeadScriptRebuttalClass().create(props)
        }
    },
    SubType: {
        new: () => new LeadSubTypeClass(),
        create: props => new LeadSubTypeClass().create(props),
    },
    Type: {
        new: () => new LeadTypeClass(),
        create: props => new LeadTypeClass().create(props),
        static: {
            get: () => staticLeadTypes
        }
    },
    Transfer: {
        new: () => new TransferClass(),
        create: props => new TransferClass().create(props),
        status: {
            in_progress: 1,
            accepted: 2,
            declined: 3,
            inactive: 4
        }
    }
}
